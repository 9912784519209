exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-how-to-unminify-javascript-css-html-xml-json-js": () => import("./../../../src/pages/blog/how-to-unminify-javascript-css-html-xml-json.js" /* webpackChunkName: "component---src-pages-blog-how-to-unminify-javascript-css-html-xml-json-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-reverse-minified-code-tips-developers-beginners-js": () => import("./../../../src/pages/blog/reverse-minified-code-tips-developers-beginners.js" /* webpackChunkName: "component---src-pages-blog-reverse-minified-code-tips-developers-beginners-js" */),
  "component---src-pages-blog-unminify-code-for-debugging-optimization-js": () => import("./../../../src/pages/blog/unminify-code-for-debugging-optimization.js" /* webpackChunkName: "component---src-pages-blog-unminify-code-for-debugging-optimization-js" */),
  "component---src-pages-blog-unminify-code-vs-code-plugins-techniques-js": () => import("./../../../src/pages/blog/unminify-code-vs-code-plugins-techniques.js" /* webpackChunkName: "component---src-pages-blog-unminify-code-vs-code-plugins-techniques-js" */),
  "component---src-pages-blog-unminify-vs-beautify-difference-when-to-use-js": () => import("./../../../src/pages/blog/unminify-vs-beautify-difference-when-to-use.js" /* webpackChunkName: "component---src-pages-blog-unminify-vs-beautify-difference-when-to-use-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-css-validator-js": () => import("./../../../src/pages/css-validator.js" /* webpackChunkName: "component---src-pages-css-validator-js" */),
  "component---src-pages-free-minify-css-js": () => import("./../../../src/pages/free-minify-css.js" /* webpackChunkName: "component---src-pages-free-minify-css-js" */),
  "component---src-pages-free-minify-html-js": () => import("./../../../src/pages/free-minify-html.js" /* webpackChunkName: "component---src-pages-free-minify-html-js" */),
  "component---src-pages-free-minify-js-js": () => import("./../../../src/pages/free-minify-js.js" /* webpackChunkName: "component---src-pages-free-minify-js-js" */),
  "component---src-pages-free-minify-json-js": () => import("./../../../src/pages/free-minify-json.js" /* webpackChunkName: "component---src-pages-free-minify-json-js" */),
  "component---src-pages-free-minify-xml-js": () => import("./../../../src/pages/free-minify-xml.js" /* webpackChunkName: "component---src-pages-free-minify-xml-js" */),
  "component---src-pages-html-validator-js": () => import("./../../../src/pages/html-validator.js" /* webpackChunkName: "component---src-pages-html-validator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-js-validator-js": () => import("./../../../src/pages/js-validator.js" /* webpackChunkName: "component---src-pages-js-validator-js" */),
  "component---src-pages-json-validator-js": () => import("./../../../src/pages/json-validator.js" /* webpackChunkName: "component---src-pages-json-validator-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-xml-validator-js": () => import("./../../../src/pages/xml-validator.js" /* webpackChunkName: "component---src-pages-xml-validator-js" */)
}

